import React, { Component } from 'react';
import axios from 'axios';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import countries from "../packs/data.json";
import { render } from "react-dom";
import PropTypes, {element} from "prop-types";
//import {forEach} from "react-bootstrap/cjs/utils/ElementChildren";
//import HelloWorld from "./HelloWorld";
// import '../style/application.scss'
var answers = [];
var objective_evidence = [];
var comments = '';
var comments_edited = false;
class MultiForm extends React.Component {

    state = {
        isLoading: false,
        selectedAnswer: null,
        gender: '',
        initial_state: 0,
        isChecked: true,
        surveys: [],
        sections: [],
        questions: [],
        answer_options: [],
        lists: [],
        objectives: [],
        country: '',
        region: '',
        showCommentSection: false,
        commentSectionText: '',
        summaries: [],
        isCommentSectionTextDirty: false
    };

    componentDidMount() {
        //console.log(this.props.app_path);
        axios.get(this.props.app_path +'/api/v1/sections/'+this.props.survey_id)
            .then(response => {
                var items = response.data.sections;
                var sorted_items= [];
                var qualitative_item ;
                var qualitative_question_answers_map = this.props.qualitative_question_answers_map;
                for (var i=0; i<items.length;i++)
                {
                    if(items[i].assessment_type === "qualitative")
                    {
                        qualitative_item = items[i];
                    }
                    else
                    {
                        sorted_items.push(items[i]);
                    }
                }
                if(qualitative_item)
                {
                    // var qualitative_questions  = qualitative_item.questions;
                    // for(var i=0; i<qualitative_questions.length; i++)
                    // {
                    //
                    //     if(parseInt(qualitative_questions[i].position) == 0)
                    //     {
                    //
                    //         if(!objective_evidence[qualitative_questions[i].id])
                    //         {
                    //             objective_evidence[qualitative_questions[i].id] = this.props.company_name;
                    //             answers[qualitative_questions[i].id] = 0;
                    //         }
                    //         break;
                    //     }
                    //
                    // }

                    if(Object.keys(qualitative_question_answers_map).length > 0){

                        Object.keys(qualitative_question_answers_map).forEach(function (key) {
                            if(!qualitative_question_answers_map[key].answer_option_id){
                                objective_evidence[key] = qualitative_question_answers_map[key].answer_text;
                                answers[key] = 0
                            }else{
                                answers[key] = qualitative_question_answers_map[key].answer_option_id
                            }
                        })

                    }
                    if(qualitative_item.questions.length > 0)
                    {
                        sorted_items.unshift(qualitative_item);
                        this.setState({
                            sections: sorted_items
                        })
                    }
                    else
                    {
                        this.setState({
                            sections: sorted_items
                        })
                    }

                }
                else
                {
                    this.setState({
                        sections: sorted_items
                    })
                }
            }).catch(error => console.log(error));

        answers = [];
        objective_evidence = [];
        comments = '';
        comments_edited = false;
        var arr = localStorage.getItem('c'+this.props.company_id+'s'+this.props.survey_id);
        if(arr!== null)
        {
            var obj = JSON.parse(arr);
            var c_id;
            var s_id;
            Object.keys(obj).forEach(function(key) {
                const keys = Object.keys(obj[key]);
                const question_id = keys[0];
                if(question_id === 'company_id')
                {
                    comments = obj[key]['comments'];
                    comments_edited = obj[key]['comments_edited'];
                }
                else
                {
                    const answer_option_id= obj[key][keys[0]];
                    const obj_evidence = obj[key][keys[1]];
                    answers[question_id] = answer_option_id;
                    objective_evidence[question_id] = obj_evidence;
                }
            });
        }
        this.setState({
            commentSectionText: comments,
            isCommentSectionTextDirty: comments_edited
        }, () => {
            console.log("MOUNT-------");
            console.log(this.state);
        })
        this.initializeCountries();

    }
    next = () => {
        //console.log(this.state.sections[this.state.initial_state]);
        //console.log('section_answer_question_numbers = ', this.state.section_answer_question_numbers);

        if(!this.completedSectionQuestions(this.state.sections[this.state.initial_state])) {
          alert(this.sectionQuestionAnswers());
        } else {
          this.setShowCommentSection(false);
          this.setState({initial_state: this.state.initial_state + 1});
          this.setState({selectedAnswer: null});
          this.setState({selectedQAnswer: []});
          $("html, body").animate({scrollTop: 0}, "fast");
        }
    };
    previous = () => {
        this.setShowCommentSection(false);
        if(!this.state.showCommentSection){
            this.setState({initial_state: this.state.initial_state - 1});
        }
        this.setState({selectedAnswer: null});
        this.setState({selectedQAnswer: []});
        $("html, body").animate({scrollTop: 0}, "fast");
    };
    save = () => {

        var current = 'c'+this.props.company_id+'s'+this.props.survey_id;
        var result = [];
        for(var i=0; i<answers.length; i++)
        {
            if(answers[i]!=null )
            {
                (typeof objective_evidence[i] === 'undefined') ? objective_evidence[i] = '' : '' ;
                result.push(
                    {
                        [i] : answers[i],
                        ob   : objective_evidence[i]
                    });
            }
        }
        const c_id = this.props.company_id;
        const s_id = this.props.survey_id;

        result.push({
            comments: this.state.commentSectionText,
            comments_edited: this.state.isCommentSectionTextDirty,
            company_id: c_id,
            survey_id: s_id,
        });
        localStorage.setItem(current, JSON.stringify(result));
        //console.log('saved... ' + localStorage.getItem(current));
        //console.log('local storage key = ', current, ' sections = ', this.state.sections);
    };
    initializeCountries = () => {
        let countryList = countries;

        if (this.props.user.role === 'country_surveyor' && this.props.user_countries) {
            countryList = countries.filter(country =>
                this.props.user_countries.includes(country.countryShortCode)
            );
        }
        this.setState({ countries: countryList });
    }
    check_duplicate_company = () => {
      if(this.props.company_name != objective_evidence[this.props.company_name_que_id]) {
        if(this.props.all_company_names.includes(objective_evidence[this.props.company_name_que_id])) {
          return true;
        } else {
          return false;
        }
      }
    };
    upload_answers = () => {
      
      if(!navigator.onLine) {
          alert('You are offline, try when you have internet connection');
          return;
      }

      let key_id = 'c' + this.props.company_id + 's' + this.props.survey_id;
      
      if(localStorage.getItem('saved_surveys') == null) {
        localStorage.setItem('saved_surveys', JSON.stringify([]));
      }

      let saved_surveys = JSON.parse(localStorage.getItem('saved_surveys'));

      if(saved_surveys.includes(key_id)) {
        this.generateSummaryByOpenAiApi();
        return;
      }

      if(!confirm('You are about to upload your answers, are you sure?')) {
        return;
      }

      var total_questions = 0;
      for(var i=0;i<this.state.sections.length;i++) {
        total_questions = total_questions + this.state.sections[i].questions.length;
      }

      var total_answers = 0;
      for(var i=0; i<answers.length; i++) {
          if(answers[i]!=null ) {
              total_answers++;
          }
      }

      if(total_answers!==total_questions) {
          alert('Please answer all the questions before uploading!');
          return;
      }

      if(this.check_duplicate_company()) {
        alert('Company with this name already exists');
        return;
      }
      
      let current = localStorage.getItem(key_id);

      if(current && current.length > 0) {
        let post_data = [];
        post_data.push(localStorage.getItem(key_id));

        axios.post(this.props.app_path+'/api/v1/sections/', { post_data })
          .then(res => {
            saved_surveys.push(key_id);
            localStorage.setItem('saved_surveys', JSON.stringify(saved_surveys));

            this.generateSummaryByOpenAiApi();
          }).catch(error =>
          console.log(error)
        );
      }
    };

    generateSummaryByOpenAiApi = () => {
      this.setState({ isLoading: true });
      // show the summary textarea section
      this.setShowCommentSection(true);

      // openai API call
      axios.get(this.props.app_path + '/api/v1/company_surveys/' + this.props.company_survey_user_id + '/summary', {})
        .then(response => {
          // set response summary recommendation to the comment textarea
          this.setState({ commentSectionText: response.data.summary });
          this.state.summaries.push(response.data.summary);
          this.setState({ isLoading: false });
        }).catch(error =>
        console.log(error)
      );
    };

    setSummaryText = (index) => {
      this.setState({ commentSectionText: this.state.summaries[index] });
      return;
    };

    getSummaryWordCount = () => {
      // Split the text by whitespace and filter out empty strings
      const words = this.state.commentSectionText.trim().split(/\s+/).filter(Boolean);
      return words.length;
    };
    
    handleChange = (e, question, answer, questionId, answerId, b, type, section) => {
        //console.log(e, question, answer, questionId, answerId, b, type, section); // b = answer question id

        var x = false;
        e.target.checked ? e.target.setAttribute("checked", "") : e.target.removeAttribute("checked");
        if (type === 'qualitative') {
            this.setState({selectedAnswer:  e.target.value});
            answers[questionId] = answerId;
        } else {
            if ( Array.isArray(answers[questionId]) === false){
                answers[questionId] = [];
            }
            if (answers[questionId].includes(answerId) == true){
                var index = answers[questionId].indexOf(answerId);
                if (index !== -1) {
                    answers[questionId].splice(index, 1);
                    this.state.selectedQAnswer.splice(index, 1);
                }
            } else {
                x = true;
                this.state.selectedQAnswer.push(e.target.value);
                answers[questionId].push(answerId);
            }
        }

        var items = document.getElementsByClassName(questionId);
        for (var i = 0; i < items.length; i++) {
            if (type === 'qualitative'){
                if (e.target.id !== items[i].getAttribute("id")) {
                    items[i].removeAttribute("checked");
                    items[i].checked = false;
                }
            } else {
                if (x === true){
                    if (e.target.id == items[i].getAttribute("id")) {
                        $(items[i]).prop('checked', true);
                    }
                }
            }
        }

        this.save();
        this.forceUpdate();
    };
    handleChangeSingle = (e, question, answer, questionId, answerId, b, type, section) => {
        //console.log("Radio button clicked");
        //console.log(e, question, answer, questionId, answerId, b, type, section);

        var x = false;
        e.target.checked ? e.target.setAttribute("checked", "") : e.target.removeAttribute("checked");
        if (type === 'qualitative') {
            this.setState({selectedAnswer:  e.target.value});
            answers[questionId] = answerId;
        } else {
            if ( Array.isArray(answers[questionId]) === false){
                answers[questionId] = [];
            }
            if (answers[questionId].includes(answerId) == true){
                // do nothing
                //console.log("It's already selected")
                answers[questionId] = [answerId];
            } else {
                x = true;
                answers[questionId] = [answerId];
            }
        }

        var items = document.getElementsByClassName(questionId);
        for (var i = 0; i < items.length; i++) {
            if (type === 'qualitative'){
                if (e.target.id !== items[i].getAttribute("id")) {
                    items[i].removeAttribute("checked");
                    items[i].checked = false;
                }
            } else {
                if (x === true){
                    if (e.target.id == items[i].getAttribute("id")) {
                        $(items[i]).prop('checked', true);
                    }
                }
            }
        }

        this.save();
        this.forceUpdate();
    };
    objective_set = (e, questionId, type) => {
        var input = e.target.value;
        e.target.removeAttribute('value');

        $('#obj'+questionId).val('');

        // $('<input type="text" style="width: 75%" />').insertBefore($('#obj-ans-'+questionId));
        if((answers[questionId]==null) && input.length > 0)
        {
            answers[questionId] = 0;
            if(type === 'qualitative'){
                answers[questionId] = 0;
            }else{
                answers[questionId] = [];
            }

        }
        if(input.length > 0)
        {
            objective_evidence[questionId] = input;
        }
        else if(input.length === 0)
        {
            // objective_evidence[questionId] = '';
        }


        this.setState({ objectives: this.state.objectives.concat({questionId: input}) }, () => {
            if(this.state.isCommentSectionTextDirty !== true){
                this.save();
                var comment_text = this.getAllQuantitativeObjEvidence();
                //console.log("OBJECTIVE SET --- " + comment_text);
                this.setState({commentSectionText: comment_text}, () => {
                    //console.log("OBJECTIVE SET STATE---"+this.state.commentSectionText);
                    this.save();
                })
            }else{
                this.save();
            }
        });

    };
    getCountryName = (countryCode) => {
        var obj = countries.find(country => country.countryShortCode === countryCode);
        return obj.countryName ;
    }

    section_change = (e) => {
        this.setShowCommentSection(false);
        this.setState({initial_state: parseInt(e.target.id)});
        this.setState({selectedAnswer: null});
        this.setState({selectedQAnswer: []});
    };
    yearChange=(year)=>{
        //console.log(year);
    };
    selectCountry= (event,questionId)=> {
        this.setState({ country: event.target.value });
        answers[questionId] = 0;
        objective_evidence[questionId] = event.target.value;
        this.save();
    };
    selectDate = (date,questionId ) => {
        var d = new Date(date);
        var month = d.toLocaleString('default', { month: 'long' });
        var year = d.getFullYear();
        var final_date = month + ', ' + year;
        //console.log(date);
        this.setState({
            startDate: date
        });
        answers[questionId] = 0;
        objective_evidence[questionId] = final_date;
        this.save();
    };
    selectRegion = (val,questionId)=> {
        this.setState({ region: val });
        answers[questionId] = 0;
        objective_evidence[questionId] = val;
        this.save();
    };
    checkedBoxes(answer, answer_id, type) {
        var ans = false;
        if (type === 'quantitative'){
            if (Array.isArray(answer) === false){
                return false;
            }
            answer.map( a => {
                if (a === answer_id){
                    ans = true;
                    return;
                }
            });
        } else {
            if(answer === answer_id ){
                ans = true;
            }
        }
        return ans;
    }

    getAllQuantitativeObjEvidence() {
        const { company_id, survey_id } = this.props
        const { sections } = this.state

        const ls_key = 'c' + company_id + 's' + survey_id
        const ls_data = JSON.parse(localStorage.getItem(ls_key))

        const quantitativeSections = sections.filter(s => s.assessment_type == "quantitative")
        const quantitativeQIDs = []

        quantitativeSections.forEach(section => {
            if (Array.isArray(section.questions)) {
                section.questions.forEach(question => quantitativeQIDs.push(question.id))
            }
        })
        let quantitativeObjEvidences = [] ;
        if(ls_data != null){
            quantitativeObjEvidences = ls_data.filter(item => {
                let isValid = false
                quantitativeQIDs.forEach(id => {
                    if (Object(item).hasOwnProperty(id) && (item.ob.length !== 0) ) {
                        isValid = true
                    }
                })
                return isValid
            })
        }
        return quantitativeObjEvidences.map(item => item.ob).join('\n')
    }

    setShowCommentSection = (value) => {
        //console.log("comment section"+this.state.commentSectionText)
        this.setState({
          showCommentSection: value === true
      })
    };

    completeSurvey = () => {
      if(this.state.commentSectionText == '') {
        alert('Summary can not be empty. Please add summary within 200 words.');
        return;
      }

      let summary = this.state.commentSectionText;
      let words = summary.split(/\s+/).filter(item => item);

      if(words.length > 200) {
        alert('Summary has to be within 200 words. You have written ' + words.length + ' words.');
        return;
      }

      if(this.state.showCommentSection && this.state.commentSectionText != '') {
        let company_survey_user = { comments: this.state.commentSectionText };

        axios.put(this.props.app_path + '/api/v1/company_surveys/' + this.props.company_survey_user_id, { company_survey_user })
          .then(res => {
            this.updateLocalStorage();

            alert('Sync was successful!');
            window.location.replace(this.props.redirect_path);
          }).catch(error =>
          console.log(error)
        );
      }
    };

    updateLocalStorage = () => {
      const key_id = 'c' + this.props.company_id + 's' + this.props.survey_id;
      let saved_surveys = JSON.parse(localStorage.getItem('saved_surveys'));
      let index = saved_surveys.indexOf(key_id);

      saved_surveys.splice(index, 1);
      localStorage.setItem('saved_surveys', JSON.stringify(saved_surveys));
      localStorage.removeItem(key_id);
    };

    handleCommentSectionTextChange = (event) => {
        this.setState({
            commentSectionText: event.target.value,
            isCommentSectionTextDirty: true
        }, () => {
            this.save()
        })

    }

    sectionQuestionAnswers = () => {
      //console.log('sectionQuestionAnswers = ', this.state.sections[this.state.initial_state]);
      let key_id        = 'c' + this.props.company_id + 's' + this.props.survey_id;

      if(localStorage.getItem(key_id) == null) {
        return "You have not answerd any of the question!";
      }

      let result        = JSON.parse(localStorage.getItem(key_id));
      let question_ids  = this.state.sections[this.state.initial_state].questions.map((q) => q.id);
      let answer_ids    = [];

      for(let i=0; i<result.length; i++) {
        let question_id = parseInt(Object.keys(result[i])[0]);

        if(question_ids.includes(question_id)) {
          answer_ids.push(question_id);
        }
      }

      let message           = "Question ";
      let question_numbers  = [];

      for(let i=0; i<this.state.sections[this.state.initial_state].questions.length; i++) {
        let question = this.state.sections[this.state.initial_state].questions[i];

        if(!answer_ids.includes(question.id)) {
          question_numbers.push(i+1);
        }
      }

      //console.log('question_numbers = ', question_numbers);

      for(let i=0; i<question_numbers.length; i++) {
        message += "#"+ question_numbers[i];

        if(i < question_numbers.length - 1) {
           message += ", "
        }
      }

      message += " have not answered yet! Please answers all of the missing questions."

      return message;
    };

    completedSectionQuestions = (section) => {
      let key_id   = 'c' + this.props.company_id + 's' + this.props.survey_id;

      if(localStorage.getItem(key_id) == null) {
        return false;
      }

      let result        = JSON.parse(localStorage.getItem(key_id));
      let question_ids  = section.questions.map((q) => q.id);
      let answers       = [];
      let match_count   = 0;

      for(let i=0; i<result.length; i++) {
        let question_id = parseInt(Object.keys(result[i])[0]);
        answers.push(question_id);

        if(question_ids.includes(question_id)) {
          match_count += 1;
        }
      }

      //console.log('answers = ', answers, ' match count = ', match_count, ' questions = ', question_ids.length);

      return question_ids.length === match_count;
    }

    render() {
        var customMargin = {
            marginTop: '25px',
        };
        const glyphiconOk = {
          color: '#27AE60',
          marginRight: '5px'
        };
        const { sections: sections, initial_state, showCommentSection, commentSectionText, isCommentSectionTextDirty, isLoading } = this.state;
        //console.log("state sections = ", this.state.sections, "render "+commentSectionText);
        var arr = localStorage.getItem('my_surveys');
        const current_timestamp =  new Date().toLocaleTimeString().split(' ')[0];

        return (
            <div className='container'>

                <div style={customMargin}>
                    <div className="alert alert-dark" role="alert">
                        <h4><strong>{this.props.survey_name}</strong></h4>
                    </div>
                    { sections.length > 0 &&
                    <div>
                        <div style={{display: "flex"}}>
                            { !showCommentSection && <div className='col-md-9'>
                                {
                                    (sections[0].assessment_type === "qualitative")?
                                        (this.state.initial_state == 0)?
                                            <h5 className=''><strong className='blue-text'>{ sections[initial_state].title }{' '} </strong></h5>
                                            :
                                            <h5 className=''><strong className='blue-text'>Section {(this.state.initial_state) +"/"+(sections.length-1) +": "}{ sections[initial_state].title }{' '} </strong></h5>
                                        :
                                        <h5 className=''><strong className='blue-text'>Section {(this.state.initial_state+1) +"/"+(sections.length) +": "}{ sections[initial_state].title }{' '} </strong></h5>
                                }
                                { sections[initial_state].questions.map(
                                    (question,index) => <div key={index}><div id={question.id} className='card card-accent-success question-card'>
                                        <div className='card-header'><strong >{index+1+". "}{question.title}</strong></div>
                                        <div className='card-body' key={index}>
                                            { (question.selection_type === "Multiple" || question.selection_type === null) && question.answer_options.map(
                                                (answer_option,id) =>
                                                    <div key={id}>
                                                        <label>
                                                            <input type = 'checkbox'
                                                                   id = {answer_option.id}
                                                                   className = {question.id + ' ' + 'answer-option'}
                                                                   value = { answer_option.title }
                                                                   onChange = { (e) => this.handleChange(e, question.title, answer_option.title, question.id, answer_option.id, answers[question.id], sections[initial_state].assessment_type, sections[initial_state]) }
                                                                   checked = { this.checkedBoxes(answers[question.id], answer_option.id, sections[initial_state].assessment_type) }
                                                            />
                                                            { ' ' + answer_option.title + '  ' }
                                                        </label></div>
                                            )
                                            }
                                            { question.selection_type === "Single" && question.answer_options.map(
                                                (answer_option, id) =>
                                                    <div key={id}>
                                                        <label>
                                                            <input type='radio'
                                                                   id={answer_option.id}
                                                                   className={question.id + ' ' + 'answer-option'}
                                                                   value={answer_option.title}
                                                                   onChange={() => undefined}
                                                                   onClick={(e) => this.handleChangeSingle(e, question.title, answer_option.title, question.id, answer_option.id, answers[question.id], sections[initial_state].assessment_type, sections[initial_state])}
                                                                   checked={this.checkedBoxes(answers[question.id], answer_option.id, sections[initial_state].assessment_type)}
                                                            />
                                                            {' ' + answer_option.title + '  '}
                                                        </label></div>
                                            )
                                            }
                                            {/*<p>Answer: <u>{ localStorage.getItem(question.title) }</u></p>*/}


                                            {((sections[this.state.initial_state].assessment_type === "qualitative") &&
                                                ((question.position ===4)||(question.position ===5)||(question.position ===6)
                                                    ||(question.position ===6)||(question.position ===7)||(question.position ===8)
                                                    ||(question.position ===10) ||(question.position ===13)
                                                    ||(question.position ===15)||(question.position ===16))) ?
                                                <div>

                                                    {((question.position ===4)||(question.position ===5)) &&
                                                    <div className={"input-group mb-3 obj-inp-"+question.id}>
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="btnGroupAddon"><i className="fa fa-calendar"></i></span>
                                                        </div>
                                                        <DatePicker
                                                            // selected={this.state.startDate}
                                                            className="form-control"
                                                            showMonthYearPicker
                                                            dateFormat="MMMM, yyyy"
                                                            onChange={(val) => this.selectDate(val,question.id)} />
                                                    </div>
                                                    }
                                                    {((question.position ===13)) &&
                                                    // <CountryDropdown
                                                    //     className="form-control"
                                                    //     value={this.state.country}
                                                    //     onChange={(val) => this.selectCountry(val,question.id)} />

                                                        <select className="form-control"
                                                                value={this.state.country}
                                                                onChange={(event) => this.selectCountry(event, question.id)}>
                                                            <option value="">Select Country</option>
                                                            {this.state.countries && this.state.countries.map((country) => (
                                                                <option key={country.countryShortCode}
                                                                        value={country.countryShortCode}>
                                                                    {country.countryName}
                                                                </option>
                                                            ))}

                                                        </select>
                                                    }
                                                    {/*{((question.position ===14)) &&*/}
                                                    {/*<RegionDropdown*/}
                                                    {/*    country={this.state.country}*/}
                                                    {/*    value={this.state.region}*/}
                                                    {/*    onChange={(val) => this.selectRegion(val,question.id)} />}*/}

                                                </div>
                                                :
                                                <div className={"input-group obj-inp-"+question.id} style={{}}>
                                                    <div className="input-group-prepend" id = {'ico'+question.id}>
                                                        <span className="input-group-text" id="btnGroupAddon"><i className="fa fa-edit"></i></span>
                                                    </div>
                                                    <input className="form-control input-field" type="text"
                                                           placeholder= {sections[initial_state].assessment_type === "qualitative" ? "Answer" : "Objective evidence"}
                                                           aria-label="Objective liya said you were evidence"
                                                           aria-describedby="btnGroupAddon"
                                                           id = {'obj'+question.id}
                                                           onBlur={(e) => this.objective_set(e,question.id, sections[initial_state].assessment_type)}
                                                           maxLength= {254}
                                                    />
                                                </div>
                                            }
                                            <br/>
                                            { objective_evidence[question.id] && <p className='input-answer' id={'obj-ans-'+question.id}><strong>{(sections[initial_state].assessment_type === "qualitative" ? "Answer" : "Objective evidence")+": " +  ((question.position === 13) ? this.getCountryName(objective_evidence[question.id]) : objective_evidence[question.id]) }</strong></p>}
                                        </div>

                                    </div> <br/></div>
                                )
                                }
                            </div>}
                            {showCommentSection &&
                            <div className='col-md-9'>
                                { isLoading && <p className='text-center'>Loading ...</p> }
                                <h5>
                                    <strong className="blue-text">Summary</strong>
                                    <button className="btn btn-sm btn-success mb-2 pull-right" title="Max 3 times is allowed." disabled={this.state.summaries.length >= 3} onClick={this.generateSummaryByOpenAiApi}>Reload</button>
                                </h5>
                                <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            id="commentSection"
                                            rows="6"
                                            placeholder="Please enter your comment..."
                                            value={commentSectionText || (isCommentSectionTextDirty ? commentSectionText : this.getAllQuantitativeObjEvidence())}
                                            onChange={this.handleCommentSectionTextChange}
                                        ></textarea>
                                        <p className="blue-text">Please enter maximum 200 words. Current word count: {this.getSummaryWordCount()}</p>
                                </div>
                                <div className="from-group">
                                  {
                                    this.state.summaries.map((data, index) => 
                                      <p className="alert alert-info" title="Choose this summary" key={index} onClick={ () => this.setSummaryText(index) }>{data}</p>
                                    )
                                  }
                                </div>
                            </div>
                            }
                            <div className='col-md-3' >
                                <div className="panel panel-default sticky-top">
                                    <div className="panel-heading blue-text">
                                        <h5><b>Section List</b></h5>
                                    </div>
                                    <ul className="list-group"  style={{ boxShadow: "0 6px 10px 0 rgba(0,0,0,.1)"}}>
                                        {sections.map((section,index)=> <li className="list-group-item" key={index}>
                                            { this.completedSectionQuestions(section) && <span style={glyphiconOk}><i className="glyphicon glyphicon-ok"></i></span> }
                                            <a id={index} className='section-name' href='#' onClick={this.section_change.bind(this)}
                                               style={index == this.state.initial_state && !showCommentSection ? {color:"#CA3663"} :{color:"#3081C8"}}>
                                                {/*{section.title.length > 23? section.title.slice(0,23)+'...' :section.title}*/}
                                                {section.title}
                                            </a></li>)
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="footer sticky" >
                            { this.state.initial_state !=0 && <button className='btn btn-md btn-outline-primary' onClick={this.previous}>Previous section</button>}{' '}
                            { this.state.initial_state + 1 != sections.length && <button className='btn btn-md btn-outline-dark' onClick={this.next}>{this.state.sections[initial_state].assessment_type == 'qualitative'?'Move to Quantitative Section':'Next section'}</button> }{' '}
                            { this.state.initial_state + 1 == sections.length && !this.state.showCommentSection && <button className='btn btn-md btn-outline-danger' onClick={this.upload_answers}>Summary Section</button>}{' '}
                            { this.state.showCommentSection && <button className='btn btn-md btn-outline-danger' onClick={this.completeSurvey}>Complete Survey</button>}{' '}
                        </div>
                        <br/>
                        <br/>
                    </div>
                    }
                </div>
            </div>
        );
        // }
    }
}
export default MultiForm
